let states = {
    AK: {state: 'AK', lat: 63.588753, lng: -154.493062, name: 'Alaska'},
    AL: {state: 'AL', lat: 32.318231, lng: -86.902298, name: 'Alabama'},
    AR: {state: 'AR', lat: 35.20105, lng: -91.831833, name: 'Arkansas'},
    AZ: {state: 'AZ', lat: 34.048928, lng: -111.093731, name: 'Arizona'},
    CA: {state: 'CA', lat: 36.778261, lng: -119.417932, name: 'California'},
    CO: {state: 'CO', lat: 39.550051, lng: -105.782067, name: 'Colorado'},
    CT: {state: 'CT', lat: 41.603221, lng: -73.087749, name: 'Connecticut'},
    DC: {state: 'DC', lat: 38.905985, lng: -77.033418, name: 'District of Columbia'},
    DE: {state: 'DE', lat: 38.910832, lng: -75.52767, name: 'Delaware'},
    FL: {state: 'FL', lat: 27.664827, lng: -81.515754, name: 'Florida'},
    GA: {state: 'GA', lat: 32.157435, lng: -82.907123, name: 'Georgia'},
    HI: {state: 'HI', lat: 19.898682, lng: -155.665857, name: 'Hawaii'},
    IA: {state: 'IA', lat: 41.878003, lng: -93.097702, name: 'Iowa'},
    ID: {state: 'ID', lat: 44.068202, lng: -114.742041, name: 'Idaho'},
    IL: {state: 'IL', lat: 40.633125, lng: -89.398528, name: 'Illinois'},
    IN: {state: 'IN', lat: 40.551217, lng: -85.602364, name: 'Indiana'},
    KS: {state: 'KS', lat: 39.011902, lng: -98.484246, name: 'Kansas'},
    KY: {state: 'KY', lat: 37.839333, lng: -84.270018, name: 'Kentucky'},
    LA: {state: 'LA', lat: 31.244823, lng: -92.145024, name: 'Louisiana'},
    MA: {state: 'MA', lat: 42.407211, lng: -71.382437, name: 'Massachusetts'},
    MD: {state: 'MD', lat: 39.045755, lng: -76.641271, name: 'Maryland'},
    ME: {state: 'ME', lat: 45.253783, lng: -69.445469, name: 'Maine'},
    MI: {state: 'MI', lat: 44.314844, lng: -85.602364, name: 'Michigan'},
    MN: {state: 'MN', lat: 46.729553, lng: -94.6859, name: 'Minnesota'},
    MO: {state: 'MO', lat: 37.964253, lng: -91.831833, name: 'Missouri'},
    MS: {state: 'MS', lat: 32.354668, lng: -89.398528, name: 'Mississippi'},
    MT: {state: 'MT', lat: 46.879682, lng: -110.362566, name: 'Montana'},
    NC: {state: 'NC', lat: 35.759573, lng: -79.0193, name: 'North Carolina'},
    ND: {state: 'ND', lat: 47.551493, lng: -101.002012, name: 'North Dakota'},
    NE: {state: 'NE', lat: 41.492537, lng: -99.901813, name: 'Nebraska'},
    NH: {state: 'NH', lat: 43.193852, lng: -71.572395, name: 'New Hampshire'},
    NJ: {state: 'NJ', lat: 40.058324, lng: -74.405661, name: 'New Jersey'},
    NM: {state: 'NM', lat: 34.97273, lng: -105.032363, name: 'New Mexico'},
    NV: {state: 'NV', lat: 38.80261, lng: -116.419389, name: 'Nevada'},
    NY: {state: 'NY', lat: 43.299428, lng: -74.217933, name: 'New York'},
    OH: {state: 'OH', lat: 40.417287, lng: -82.907123, name: 'Ohio'},
    OK: {state: 'OK', lat: 35.007752, lng: -97.092877, name: 'Oklahoma'},
    OR: {state: 'OR', lat: 43.804133, lng: -120.554201, name: 'Oregon'},
    PA: {state: 'PA', lat: 41.203322, lng: -77.194525, name: 'Pennsylvania'},
    PR: {state: 'PR', lat: 18.220833, lng: -66.590149, name: 'Puerto Rico'},
    RI: {state: 'RI', lat: 41.580095, lng: -71.477429, name: 'Rhode Island'},
    SC: {state: 'SC', lat: 33.836081, lng: -81.163725, name: 'South Carolina'},
    SD: {state: 'SD', lat: 43.969515, lng: -99.901813, name: 'South Dakota'},
    TN: {state: 'TN', lat: 35.517491, lng: -86.580447, name: 'Tennessee'},
    TX: {state: 'TX', lat: 31.968599, lng: -99.901813, name: 'Texas'},
    UT: {state: 'UT', lat: 39.32098, lng: -111.093731, name: 'Utah'},
    VA: {state: 'VA', lat: 37.431573, lng: -78.656894, name: 'Virginia'},
    VT: {state: 'VT', lat: 44.558803, lng: -72.577841, name: 'Vermont'},
    WA: {state: 'WA', lat: 47.751074, lng: -120.740139, name: 'Washington'},
    WI: {state: 'WI', lat: 43.78444, lng: -88.787868, name: 'Wisconsin'},
    WV: {state: 'WV', lat: 38.597626, lng: -80.454903, name: 'West Virginia'},
    WY: {state: 'WY', lat: 43.075968, lng: -107.290284, name: 'Wyoming'},
};

module.exports = {
    states: states,
    regions: {
        southeast: {
            AL: states.AL,
            FL: states.FL,
            GA: states.GA,
            NC: states.NC,
            SC: states.SC,
            TN: states.TN
        },
        northeast: {
            CT: states.CT,
            DE: states.DE,
            IN: states.IN,
            KY: states.KY,
            ME: states.ME,
            MD: states.MD,
            MA: states.MA,
            NH: states.NH,
            NJ: states.NJ,
            NY: states.NY,
            PA: states.PA,
            RI: states.RI,
            VT: states.VT,
            VA: states.VA,
            WA: states.WA,
            WV: states.WV
        },
        mountain: {
            AZ: states.AZ,
            CO: states.CO,
            ID: states.ID,
            MT: states.MT,
            NM: states.NM,
            UT: states.UT,
            WY: states.WY
        },
        pacific: {
            CA: states.CA,
            NV: states.NV,
            OR: states.OR,
            WA: states.WA
        },
        central: {
            AR: states.AR,
            IL: states.IL,
            IA: states.IA,
            KS: states.KS,
            LA: states.LA,
            MI: states.MI,
            MS: states.MS,
            MO: states.MO,
            NE: states.NE,
            ND: states.ND,
            OH: states.OH,
            OK: states.OK,
            SD: states.SD,
            TX: states.TX,
            WI: states.WI
        }
    }
};