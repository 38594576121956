module.exports = [
  'Bit',
  'Blanket',
  'Blanket Bars',
  'Bodywork (Bemer, PEMF etc.)',
  'Bonnet',
  'Breastplate',
  'Bridle',
  'Buckets',
  'Coolers',
  'Cross Country Boots',
  'Dressage Ring/Letters',
  'Feeders',
  'Girth',
  'Half Pads/Shim Pads',
  'Halter',
  'Hay Steamer',
  'Horse Boots',
  'Horse Trailer',
  'Jump Cups',
  'Jumps',
  'Lunging Gear',
  'Martingale',
  'Quarter Sheet',
  'Reins',
  'Saddle',
  'Saddle Pad',
  'Spurs/Spur Straps',
  'Stirrups',
  'Troughs',
  'Whips',
  'Wraps'
];