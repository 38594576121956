<template>
  <div id="search-container">
    <div class="modal fade not_found_modal">
      <div class="modal-dialog modal-s" style="text-align: center;">
        <div class="modal-content">
          <div class="container">
            <div class="modal-header">
              <h4 class="modal-title"></h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row" style="justify-content: center; padding: 16px 16px 0;">
              <p>Thank you for visiting Mane Street Market.</p>
            </div>
            <div class="row" style="justify-content: center; padding: 0 16px;">
              <p>This equipment is no longer available.</p>
            </div>
            <div class="row" style="justify-content: center; padding: 0 16px 16px;">
              <p>(please click on the "x" to close this message and view other available equipment)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade disclaimer_modal">
      <div class="modal-dialog modal-s" style="text-align: center;">
        <div class="modal-content">
          <div class="container">
            <div class="modal-header">
              <h4 class="modal-title"></h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row" style="justify-content: center; padding: 16px;">
              <p>Mane Street Market is not responsbile for the quality of the items advertised. Buyers must do their due diligence in affirming quality with seller.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade detail_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <Detail :item="show_detail" :type="show_detail_type" v-if="show_detail !== null"></Detail>
        </div>
      </div>
    </div>
    <div class="modal fade list_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <List :items="cluster_items" :type="'equipment'" v-if="cluster_items_loaded"></List>
        </div>
      </div>
    </div>
    <div class="modal fade ads_modal" data-keyboard="false" data-backdrop="static" v-if="isMobile()">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Sponsored</h4>
            <p v-if="!adsLoaded" style="margin: 0; align-self: center; margin-right: 8px;">Please wait...</p>
            <p v-if="adsLoaded && skipAdCountdown > 0" style="margin: 0; align-self: center; margin-right: 8px;">{{ skipAdCountdown }}</p>
            <button v-if="skipAdCountdown === 0" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="sponsors map-list-view">
            <div class="items">
              <AdListView @loaded="adsLoaded = true" :shouldAutoscroll="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade search_filters_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Filters</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Equipment Type</label>
              <div>
                <a-select v-model:value="filters.type" mode="tags" style="width: 100%" :virtual="false">
                  <a-select-optgroup label="Horse">
                    <a-select-option :value="he_type" :key="he_type" v-for="he_type in horse_equipment_types">{{he_type}}</a-select-option>
                  </a-select-optgroup>
                  <a-select-optgroup label="People">
                    <a-select-option :value="pe_type" :key="pe_type" v-for="pe_type in people_equipment_types">{{pe_type}}</a-select-option>
                  </a-select-optgroup>
                </a-select>
              </div>
            </div>
            <div class="form-group">
              <label>Condition</label>
              <div>
                <a-radio-group v-model:value="filters.condition">
                  <a-radio-button value="Any">Any</a-radio-button>
                  <a-radio-button value="NEW">New</a-radio-button>
                  <a-radio-button value="USED">Used</a-radio-button>
                </a-radio-group>
              </div>
            </div>
            <div class="d-flex justify-content-start">
              <div class="form-group">
                <label>Min. Price</label>
                <div>
                  <a-input v-model:value="filters.min_price" style="width: 150px;" />
                </div>
              </div>
              <div class="form-group" style="margin-left: 20px;">
                <label>Max. Price</label>
                <div>
                  <a-input v-model:value="filters.max_price" style="width: 150px;" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Brand</label>
              <div>
                <a-input v-model:value="filters.brand" style="width: 100%" :virtual="false"></a-input>
              </div>
            </div>
            <div class="form-group">
              <label>Size</label>
              <div>
                <a-input v-model:value="filters.size" style="width: 100%" :virtual="false"></a-input>
              </div>
            </div>
            <div class="form-group">
              <label>Discipline</label>
              <div>
                <a-input v-model:value="filters.discipline" style="width: 100%" :virtual="false"></a-input>
              </div>
            </div>
            <div class="form-group">
              <label>Model</label>
              <div>
                <a-input v-model:value="filters.model" style="width: 100%" :virtual="false"></a-input>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-link" @click="clearFilters();">Clear all</button>
            <button class="btn btn-primary" @click="applyFilters();">Apply Filters</button>
          </div>
        </div>
      </div>
    </div>
    <SubHeader :custom-class="{search_sub_header: true}"></SubHeader>
    <div class="filter-container">
      <a-input class="location" id="autocomplete_search" v-model="address" style="margin-right: 2px;" placeholder="Search by Location">
        <template #prefix><search-outlined /></template>
      </a-input>
      <a-button class="d-flex align-items-center" v-if="address_found" @click="clearAddress()"><close-outlined /></a-button>
      <a-radio-group v-model:value="view_type" button-style="solid" class="list-style-buttons">
        <a-radio-button value="map">
          <span class="desktop-only">View All On Map</span>
          <span class="mobile-only">Map</span>
        </a-radio-button>
        <a-radio-button value="list">
          <span class="desktop-only">View All On List</span>
          <span class="mobile-only">List</span>
        </a-radio-button>
      </a-radio-group>
      <a-button type="primary" @click="openFilters()" style="margin-right: 20px;">
        <span class="desktop-only">Filters</span>
        <span class="mobile-only"><FilterOutlined /></span>
      </a-button>
      <router-link class="ant-btn ant-btn-primary" style="margin-left: auto; margin-right: 0;" to="/save-equipment">List an Equipment</router-link>
    </div>
    <div class="result-container">
      <div class="list_view" v-if="view_type === 'list'">
        <div class="items">
          <ListView v-model:items="filteredItems" :loading="loading" />
        </div>
      </div>
      <div class="map_view" v-if="view_type === 'map'">
        <MapView v-model:items="items" :loading="loading" />
      </div>
      <div class="sponsors map-list-view autoscroll-map-list-view">
        <div class="items">
          <AdListView :shouldAutoscroll="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import states from '@/constants/states';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from "@/components/SubHeader.vue";
import ListView from "@/components/SearchEquipmentsListView.vue";
import AdListView from "@/components/AdListView.vue";
import MapView from "@/components/SearchEquipmentsMapView.vue";
import Detail from '@/components/Detail.vue';
import List from '@/components/ClusterListView.vue';

import {SearchOutlined, CloseOutlined, FilterOutlined} from '@ant-design/icons-vue';
import AInput from 'ant-design-vue/lib/input';
import AInputNumber from 'ant-design-vue/lib/input-number';
import ARadio from 'ant-design-vue/lib/radio';
import AButton from 'ant-design-vue/lib/button';
import ASelect from 'ant-design-vue/lib/select';
import 'ant-design-vue/lib/input/style/index.css';
import 'ant-design-vue/lib/input-number/style/index.css';
import 'ant-design-vue/lib/radio/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/select/style/index.css';
import axios from "axios";
import {Loader} from "@googlemaps/js-api-loader";
import Constants from '@/constants/constants';
import HorseEquipmentTypes from '@/constants/horse_equipment_types';
import PeopleEquipmentTypes from '@/constants/people_equipment_types';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ListView,
    AdListView,
    MapView,
    Detail,
    List,
    AInput,
    AButton,
    ASelect,
    SearchOutlined,
    CloseOutlined,
    FilterOutlined,
    'a-input-number': AInputNumber,
    'a-select-option': ASelect.Option,
    'a-select-optgroup': ASelect.OptGroup,
    'a-radio-group': ARadio.Group,
    'a-radio-button': ARadio.Button,
  },
  data() {
    return {
      loading: false,
      view_type: 'map',
      formatter: null,
      items: [],
      cluster_items: [],
      cluster_items_loaded: false,
      selected_item: null,
      show_detail: null,
      show_detail_type: 'equipment',
      filters: {
        type: ['Any'],
        condition: 'Any',
        min_price: null,
        max_price: null,
        brand: '',
        size: '',
        discipline: '',
        model: ''
      },
      address: null,
      address_search_enabled: false,
      google_maps_loader: null,
      mapAutoComplete: null,
      mapAutoCompleteListener: null,
      geocoder: null,
      address_found: false,
      state_found: null,
      skipAdInterval: null,
      skipAdCountdown: 5,
      adsLoaded: false,
      horse_equipment_types: HorseEquipmentTypes,
      people_equipment_types: PeopleEquipmentTypes,
    }
  },
  async mounted() {
    let $this = this;
    document.title = 'Search Equipment - Mane Street Market';
    window.scrollTo(0, 0);
    // $('.disclaimer_modal').modal('show');
    if (this.isMobile()) {
      $('.ads_modal').modal('show');
    }
    $('.detail_modal').on('shown.bs.modal', function (e) {
      $this.show_detail = $this.selected_item;
    });
    $('.detail_modal').on('hidden.bs.modal', function (e) {
      let queryParams = $this.$route.query;
      if (queryParams.id) {
        queryParams.id = undefined;
        $this.$router.replace({path: '/search-equipment', query: queryParams});
      }
      $this.selected_item = null;
      $this.show_detail = null;
    });
    $('.list_modal').on('shown.bs.modal', function (e) {
      $this.cluster_items_loaded = true;
    });
    $('.list_modal').on('hidden.bs.modal', function (e) {
      $this.cluster_items_loaded = false;
      $this.cluster_items = [];
    });
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    this.google_maps_loader = new Loader({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      libraries: ['places'],
      version: Constants.GOOGLE_MAPS_VERSION
    });
    await this.google_maps_loader.load();
    this.geocoder = new window.google.maps.Geocoder();
    this.mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete_search'));
    this.mapAutoComplete.setComponentRestrictions({
      country: ["us", "ca", "ie", "uk"],
    });
    this.mapAutoCompleteListener = window.google.maps.event.addListener(this.mapAutoComplete, 'place_changed', this.searchOnMap);
    this.address_search_enabled = true;

    if (this.$route.query.type != null) {
      this.filters.type = [this.$route.query.type];
    }
    if (this.$route.query.condition != null) {
      this.filters.condition = this.$route.query.condition;
    }
    if (this.$route.query.min_price != null) {
      this.filters.min_price = this.$route.query.min_price;
    }
    if (this.$route.query.max_price != null) {
      this.filters.max_price = this.$route.query.max_price;
    }
    if (this.$route.query.brand != null) {
      this.filters.brand = this.$route.query.brand;
    }
    if (this.$route.query.size != null) {
      this.filters.size = this.$route.query.size;
    }
    if (this.$route.query.discipline != null) {
      this.filters.discipline = this.$route.query.discipline;
    }
    if (this.$route.query.model != null) {
      this.filters.model = this.$route.query.model;
    }
    if (this.$route.query.lat != null && this.$route.query.lng != null) {
      this.address_found = true;
    }
    this.findState();
    await this.search(true);
  },
  beforeUnmount() {
    $('.detail_modal').modal('hide');
    if (this.skipAdInterval) {
      clearInterval(this.skipAdInterval);
    }
  },
  unmounted() {
    if (this.address_search_enabled) {
      window.google.maps.event.removeListener(this.mapAutoCompleteListener);
      window.google.maps.event.clearInstanceListeners(this.mapAutoComplete);
      this.google_maps_loader.deleteScript();
    }
  },
  computed: {
    filteredItems() {
      if (this.state_found == null) {
        return this.items;
      }
      let stateList = [];
      if (this.$route.query.region != null) {
        stateList = Object.keys(states.regions[this.$route.query.region]);
      } else {
        stateList.push(this.state_found);
      }
      let filteredList = filter(this.items, (item) => {
        return item.address.state && stateList.length > 0 && stateList.indexOf(item.address.state) > -1;
      });
      if (this.$route.query.lat != null && this.$route.query.lng != null) {
        filteredList = sortBy(filteredList, item => {
          return this.distanceFromMe(item);
        });
      }
      return filteredList;
    }
  },
  watch: {
    async '$route.query'() {
      if (this.$route.query.lat != null && this.$route.query.lng != null) {
        this.address_found = true;
      }
      this.findState();
      if (this.$route.query.id == null && this.items.length === 0) {
        await this.search();
      }
    },
    'filters.min_price'() {
      if (this.filters.min_price) {
        let price = this.filters.min_price.toString().replace(/[^\d]/g, '');
        this.filters.min_price = this.formatter.format(price);
      } else if (this.filters.min_price == null || this.filters.min_price.trim() === '') {
        this.filters.min_price = null;
      }
    },
    'filters.max_price'() {
      if (this.filters.max_price) {
        let price = this.filters.max_price.toString().replace(/[^\d]/g, '');
        this.filters.max_price = this.formatter.format(price);
      } else if (this.filters.max_price == null || this.filters.max_price.trim() === '') {
        this.filters.max_price = null;
      }
    },
    'filters.type'(newValue) {
      let anyIndex = newValue.indexOf('Any');
      if (Array.isArray(newValue) && anyIndex > -1 && newValue.length > 1) {
        newValue.splice(anyIndex, 1);
      } else if (newValue.length === 0 && anyIndex === -1) {
        newValue = ['Any'];
      }
      this.filters.type = newValue;
    },
    adsLoaded(newValue) {
      if (newValue) {
        this.skipAdInterval = setInterval(() => {
          if (this.skipAdCountdown === 0) {
            clearInterval(this.skipAdInterval);
            return;
          }
          this.skipAdCountdown--;
        }, 1000);
      }
    }
  },
  methods: {
    isMobile() {
      return false; // 2024-07-30: Disable "Sponsored" mobile pop-up as it is getting annoying for users
      let ua = navigator.userAgent || navigator.vendor || window.opera;
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0,4))) {
        return true;
      }
      return false;
    },
    async searchOnMap() {
      this.address_found = true;
      let place = this.mapAutoComplete.getPlace();
      let query = {lat: place.geometry['location'].lat(), lng: place.geometry['location'].lng()};
      this.$router.push({path: '/search-equipment', query: query});
    },
    async search(shouldShowPopup = false) {
      this.loading = true;
      try {
        let params = {
          type: this.filters.type,
          condition: this.filters.condition,
          min_price: this.filters.min_price,
          max_price: this.filters.max_price,
          brand: this.filters.brand,
          size: this.filters.size,
          discipline: this.filters.discipline,
          model: this.filters.model
        };
        let response = await axios.get('/api/search-equipments', {params: params});
        this.items = response.data.items;
        if (this.$route.query.id != null) {
          let listingFound = false;
          for (const item of this.items) {
            if (this.$route.query.id === item.id) {
              listingFound = true;
              this.openDetails(item);
              break;
            }
          }
          if (!listingFound && shouldShowPopup) {
            $('.not_found_modal').modal('show');
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    findState() {
      if (this.$route.query.lat != null && this.$route.query.lng != null) {
        this.geocoder.geocode({location: {lat: parseFloat(this.$route.query.lat), lng: parseFloat(this.$route.query.lng)}}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              for (const component of results[0].address_components) {
                if (component.types[0] === 'administrative_area_level_1') {
                  this.state_found = component.short_name;
                }
              }
            }
          }
        });
      } else {
        this.state_found = null;
      }
    },
    clearAddress() {
      document.getElementById('autocomplete_search').value = '';
      this.address_found = false;
      this.address = null;
      this.state_found = null;
      this.$router.push({path: '/search-equipment', query: {v: (new Date()).getTime()}});
    },
    openFilters() {
      $('.search_filters_modal').modal('show');
    },
    clearFilters() {
      this.filters.type = ['Any'];
      this.filters.condition = 'Any';
      this.filters.min_price = null;
      this.filters.max_price = null;
      this.filters.brand = '';
      this.filters.size = '';
      this.filters.discipline = '';
      this.filters.model = '';
      this.applyFilters();
    },
    applyFilters() {
      $('.search_filters_modal').modal('hide');
      this.items = [];
      let params = {
        type: this.filters.type,
        condition: this.filters.condition,
        min_price: this.filters.min_price,
        max_price: this.filters.max_price,
        brand: this.filters.brand,
        size: this.filters.size,
        discipline: this.filters.discipline,
        model: this.filters.model
      };
      params.v = (new Date()).getTime();
      if (this.$route.query.lat !== null && this.$route.query.lng !== null) {
        params.lat = this.$route.query.lat;
        params.lng = this.$route.query.lng;
      }
      this.$router.push({path: '/search-equipment', query: params});
    },
    openDetails(item, type = 'equipment') {
      this.selected_item = item;
      this.show_detail_type = type;
      this.$router.replace({path: '/search-equipment', query: {...this.$route.query, id: item.id}});
      $('.detail_modal').modal('show');
    },
    openList(ids) {
      this.cluster_items = this.items.filter(it => ids.includes(it.id));
      $('.list_modal').modal('show');
    },
    distanceFromMe(item) {
      if (item.address?.lat == null || item.address?.lng == null) {
        return Math.min(); // positive infinity
      }

      return this.haversine_distance(this.$route.query.lat, this.$route.query.lng, item.address.lat, item.address.lng);
    },
    haversine_distance(lat1, lng1, lat2, lng2) {
      var R = 3958.8; // Radius of the Earth in miles
      var rlat1 = lat1 * (Math.PI/180); // Convert degrees to radians
      var rlat2 = lat2 * (Math.PI/180); // Convert degrees to radians
      var difflat = rlat2-rlat1; // Radian difference (latitudes)
      var difflon = (lng2-lng1) * (Math.PI/180); // Radian difference (longitudes)

      var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
      return d;
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-only {
    display: none;
  }
  @media only screen and (max-width: 960px) {
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: flex;
    }
  }

  ::v-deep(.sub-header) {
    margin-bottom: 0;
  }

  #search-container {
    background: #fff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .filter-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 30px;
      flex-wrap: wrap;
      gap: 8px;

      .location {
        width: 300px;
      }

      .service_provider_list {
        width: 300px
      }

      @media only screen and (max-width: 960px) {
        padding: 8px 10px;

        .location {
          width: 200px;
        }

        .service_provider_list {
          display: block;
          width: 100%;
          flex-grow: 1;
          margin-top: 5px;
        }
      }
    }

    .result-container {
      height: 100%;
      width: 100%;
      position: relative;
      border-top: 1px solid #ddd;

      .map_view, .list_view {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 500px;

        @media only screen and (max-width: 960px) {
          right: 0px;
        }
      }

      .list_view {
        overflow: hidden;
        overflow-y: auto;
        right: 500px;

        .items {
          padding: 30px;

          @media only screen and (max-width: 480px) {
            padding: 16px 10px;
          }
        }

        @media only screen and (max-width: 960px) {
          right: 0px;
        }
      }

      .sponsors {
        position: absolute;
        right: 0;
        width: 500px;
        bottom: 0;
        top: 0;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: -2px 2px 5px 0 rgba(0,0,0,.4);

        .items {
          padding: 30px;
        }

        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }

      .map-list-view {
        position: absolute;
        right: 0;
        width: 500px;
        bottom: 0;
        top: 0;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: -2px 2px 5px 0 rgba(0,0,0,.4);

        @media only screen and (max-width: 1000px) {
          display: none;
        }

        .items {
          padding: 30px;
        }
      }
    }
  }

  .modal {
    overflow: auto !important;
  }
</style>
