<template>
  <div class="d-flex justify-content-center align-items-center loading" v-if="loading"><a-spin /></div>
  <div class="d-flex justify-content-between flex-wrap" v-if="loading === false" >
    <ListingBlock v-for="item in allItems" :type="item[1]" :item="item[0]" :key="`${ item[1] }_${ item[0].id }`" />
    <div v-if="allItems.length === 0">
      No result found!
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/spin/style/index.css';
import ListingBlock from '@/components/ListingBlock.vue';
import axios from "axios";

export default {
  components: {
    ASpin,
    ListingBlock
  },
  props: {
    shouldAutoscroll: Boolean
  },
  data() {
    return {
      loading: true,
      horses: [],
      services: [],
      ads: [],
      autoScrollTimer: null,
      restartTimer: null
    }
  },
  computed: {
    filteredHorses() {
      return filter(this.horses, (item) => {
        return item.package_name && item.package_name !== 'basic';
      });
    },
    filteredServices() {
      return filter(this.services, (item) => {
        return item.package_name && item.package_name !== 'basic';
      });
    },
    allItems() {
      let horses = this.filteredHorses.map(h => ([h, 'horse']));
      let services = this.filteredServices.map(s => ([s, 'service']));
      let ads = this.ads.map(a => ([a, 'ad']));
      let all = horses.concat(services).concat(ads);
      all.sort(() => .5 - Math.random());
      return all;
    }
  },
  async mounted() {
    setTimeout(async () => {
      try {
        let response = await axios.get('/api/list-ad-listings');
        this.horses = response.data.horses;
        this.services = response.data.services;
        this.ads = response.data.ads;
      } catch (e) {

      } finally {
        this.loading = false;
        this.$emit('loaded', true);
        if (this.shouldAutoscroll) {
          this.setupAutoscroll();
        }
      }
    }, 1000);
  },
  beforeUnmount() {
    this.teardownAutoscroll();
  },
  methods: {
    setupAutoscroll() {
      let $this = this;
      let fps = 100;
      let speedFactor = 0.001;
      let minDelta = 0.5;
      let autoScrollSpeed = 10;
      let isScrolling = false;
      let prevPos = 0, currentPos = 0;
      let currentTime, prevTime, timeDiff;
      let reverse = false;

      let elem = document.getElementsByClassName('autoscroll-map-list-view')[0];

      elem.addEventListener("scroll", function (e) {
        currentPos = elem.scrollTop || currentPos;
      });

      elem.addEventListener("wheel", handleManualScroll);
      elem.addEventListener("touchmove", handleManualScroll);

      function handleManualScroll() {
        currentPos = elem.scrollTop || currentPos;
        clearInterval($this.autoScrollTimer);
        if ($this.restartTimer) {
          clearTimeout($this.restartTimer);
        }
        $this.restartTimer = setTimeout(() => {
          prevTime = null;
          setAutoScroll();
        }, 50);
      }

      function setAutoScroll(newValue) {
        if (newValue) {
          autoScrollSpeed = speedFactor * newValue;
        }
        if ($this.autoScrollTimer) {
          clearInterval($this.autoScrollTimer);
        }
        $this.autoScrollTimer = setInterval(function() {
          if (elem.scrollHeight === Math.ceil(elem.scrollTop + elem.offsetHeight)) {
            reverse = true;
          } else if (Math.floor(elem.scrollTop) === 0) {
            reverse = false;
          }
          currentTime = Date.now();
          if (prevTime) {
            if (!isScrolling) {
              timeDiff = currentTime - prevTime;
              if (reverse) {
                currentPos = Math.round(currentPos - autoScrollSpeed * timeDiff);
              } else {
                currentPos = Math.round(currentPos + autoScrollSpeed * timeDiff);
              }
              if (Math.abs(currentPos - prevPos) >= minDelta) {
                isScrolling = true;
                elem.scrollTo(0, currentPos);
                isScrolling = false;
                prevPos = currentPos;
                prevTime = currentTime;
              }
            }
          } else {
            prevTime = currentTime;
          }
        }, 1000 / fps);
      }

      setAutoScroll(20);
    },
    teardownAutoscroll() {
      if (this.autoScrollTimer) {
        clearInterval(this.autoScrollTimer);
      }
      if (this.restartTimer) {
        clearTimeout(this.restartTimer);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading {

    @media (max-width: 768px) {
      margin: 2rem;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
</style>
