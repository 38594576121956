module.exports = [
  'Boots',
  'Breeches',
  'Chaps/Half Chaps',
  'Helmet',
  'Jackets',
  'Jeans',
  'Safety Vests',
  'Shirts',
  'Show Coats'
];