<template>
  <div class="container">
    <div class="modal-header">
      <h4 class="modal-title">Listings</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-start flex-wrap">
        <div class="item" v-for="item in items" :key="item.id">
          <div class="cover" style="width: 120px; height: 150px; margin-right: 10px; position: relative">
            <div style="position: absolute; right: 0; top: 0;">
              <a href="javascript:;" style="padding: 5px; border-bottom-left-radius: 5px; background: #fff;" @click="toggleFavorite(item);">
                <HeartOutlined v-if="isFavorited(item) === false" />
                <HeartFilled v-if="isFavorited(item)" />
              </a>
            </div>
            <img @click.self="$parent.openDetails(item)" src="../assets/img/placeholder_logo.png" v-if="item.images == null || item.images?.length === 0" style="object-fit: contain; width: 120px; height: 150px; border: none; padding: 8px;" />
            <img @click.self="$parent.openDetails(item)" :src="item.images[0]" v-if="item.images != null && item.images?.length > 0" style="object-fit: cover; width: 120px; height: 150px;" />
          </div>
          <div class="details" @click="$parent.openDetails(item)" v-if="type === 'horse'">
            <div class="price" v-if="item.for_sale_status === SaleStatus.WithPrice || item.price_must_contact === false" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">{{ formatPrice(item.price) }}</div>
            <div class="price" v-if="item.for_sale_status === SaleStatus.WithContact || item.price_must_contact" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">Contact seller for price</div>
            <div class="price" v-if="item.for_sale_status === SaleStatus.NotAvailable" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">For Lease</div>
            <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithPrice" style="margin-top: -5px; margin-bottom: 5px; font-size: 12px; font-weight: bold; color: gray;">Lease for {{ formatPrice(item.lease_price) }}/year</div>
            <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithContact" style="margin-top: -5px; margin-bottom: 5px; font-size: 12px; font-weight: bold; color: gray;">Contact seller for lease price</div>
            <div class="name"><strong>Name: </strong> {{item.horse_name}}</div>
            <div class="breed" v-if="item.breed.length > 0"><strong>Breed: </strong>{{item.breed[0]}}</div>
            <div class="discipline" v-if="item.discipline.length > 0"><strong>Discipline: </strong>{{item.discipline.join(', ')}}</div>
            <div class="age"><strong>Age: </strong>{{item.age}} {{item.age_unit === 'MONTH' ? 'months' : 'years'}} old</div>
            <div class="location" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
            <div class="location" v-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
          </div>
          <div class="details" @click="$parent.openDetails(item)" v-if="type === 'service'">
            <div class="name"><strong>Name: </strong> {{item.business_name}}</div>
            <div class="name"><strong>Type: </strong> {{item.type}}</div>
            <div class="location" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
            <div class="location" v-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
          </div>
          <div class="details" @click="$parent.openDetails(item)" v-if="type === 'equipment'">
            <div class="price" v-if="item.price" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">{{ item.price ? formatPrice(item.price) : '-' }}</div>
            <div class="name"><strong>Name: </strong> {{item.listing_name}}</div>
            <div class="name"><strong>Type: </strong> {{item.type}}</div>
            <div class="location" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
            <div class="location" v-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
          </div>
        </div>
        <div v-if="items.length === 0">
          No result found!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue';
import $ from 'jquery';
import { SaleStatus } from '@/enums/sale_status';

export default {
  components: {
    HeartOutlined,
    HeartFilled
  },
  props: {
    items: Object,
    type: String
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
      SaleStatus
    }
  },
  methods: {
    formatPrice(value) {
      return this.formatter.format(value);
    },
    toggleFavorite(item) {
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      if (this.type === 'horse') {
        this.$store.dispatch('toggleFavoriteHorse', item.id);
      } else if (this.type === 'service') {
        this.$store.dispatch('toggleFavoriteService', item.id);
      } else if (this.type === 'equipment') {
        this.$store.dispatch('toggleFavoriteEquipment', item.id);
      }
    },
    isFavorited(item) {
      if (this.type === 'horse') {
        return this.$store.state.favorite_horses.indexOf(item.id) > -1;
      } else if (this.type === 'service') {
        return this.$store.state.favorite_services.indexOf(item.id) > -1;
      } else if (this.type === 'equipment') {
        return this.$store.state.favorite_equipments.indexOf(item.id) > -1;
      }
    },
    onCarouselChange(i) {
      if (i === this.item.images.length) {
        if (this.player == null) {
          this.initializePlayer();
          setTimeout(() => this.initializePlayer(), 500); // for some reason initialization needs to be done twice
        }
      } else {
        if (this.player != null) {
          this.player.pause();
        }
      }
    },
    beforeCarouselChange(from, to) {
      if (from === this.item.images.length) {
        if (this.player != null) {
          this.player.pause();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .price {
    margin-top: 10px;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    width: 48%;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border: 1px solid #ddd;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      width: 100%;
      margin-right: 0;
    }
  }

</style>
